body {
  background-color: rgb(236, 246, 248);
}
.w-100{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
}
.h-100{
  height: 100%;
}
.w-25{
  width: 25%;
}
.w-15{
  width: 15%;
}
.side-bg{
  background-color: aliceblue;
}
.my-3{
  margin: 15px auto;
}
.container{
  max-width: 1300px;
}
.d-flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.btn{
  padding: 8px 5px;
  margin: 5px;
}



.cursorpointer {
  cursor: pointer;
}



.list {
  list-style: none;
  font-weight: bold;
  margin: 20px 0px;
  padding: 10px 0px;
}

.bg-danger {
  background-color: red;
}

.form {
  width: 99%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form form {
  width: 50%;
}

.form form input {
  width: 100%;
  outline: none;
  padding: 10px 0px;
  padding-left: 5px;
}

.form form textarea {
  width: 100%;
  border-radius: 15px;
  outline: none;
  padding-left: 10px;
}

.btn1 {
  width: 20% !important;
  padding: 10px 0px;
  cursor: pointer;
}

.form1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form1 input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  border-radius: 10px;
}

.form1 textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
}

.form1 label {
  display: flex;
  width: 50%;
  flex-direction: column;
}

ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

li {
  display: flex;
  justify-content: space-between;
  width: 80%;
  gap: 10px;
  align-items: center;
}





.updateDelete {
  display: flex;
  gap: 20px;
}

.cimage {
  width: 100px;
}

.updatefile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trending-box {
  width: 51px !important;
  height: 32px !important;
}

.max-img-width{
  max-height: 200px;
}